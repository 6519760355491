<template>
  <div class="common-layout">
    <el-row>
      <el-col :span="16" :xs="24">
        <div v-if="insights">
          <DeviceStates />
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="16" :xs="24">
        <el-card class="mt-3">
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item>
              <el-input
                style=" mr-1"
                @keyup.enter="handleFilter"
                placeholder="Nom"
                v-model="nom"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="orgid"
                placeholder="Organisation"
                clearable
                filterable
                remote
                :remote-method="getOrganizations"
                :loading="loading"
                @keyup.enter="handleFilter"
              >
                <el-option
                  v-for="item in orgOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button
                right
                type="primary"
                @click="handleFilter"
                plain
                style="margin-left: 12px"
                >Filtrer
              </el-button>
            </el-form-item>
          </el-form>

          <el-table :data="list" style="width: 100%; margin-bottom: 10px">
            <el-table-column prop="uniqueId" label="Numéro de série" />
            <el-table-column prop="name" label="Nom" />
            <el-table-column prop="organization.name" label="Organisation" />
            <el-table-column prop="status" label="Statut" />
          </el-table>
          <el-pagination
            v-if="list.length"
            v-model:currentPage="page"
            v-model:page-size="size"
            background
            layout="total, prev, pager, next, jumper"
            :total="totalElements"
            @current-change="setPage"
            :hide-on-single-page="true"
          />
        </el-card>
      </el-col>
    </el-row>
  </div>
  <!-- <el-container direction="vertical">
    <el-row>
      <el-col :span="1">
        <div v-if="insights">
          <DeviceStates />
        </div>
        <el-row v-else type="flex" justify="center">
          <el-empty description="Pas d'informations"></el-empty>
        </el-row>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="11">
        <div style="margin-top: 400px; width: 171.2%; margin-left: 70px">
          <el-card class="row mt-3 align-items-center">
            <div class="col-md-4 mb-2 pr-0">
              <el-input
                style=" mr-1"
                @keyup.enter="handleFilterr"
                placeholder="Nom"
                v-model="nom"
              >
              </el-input>
            </div>
            <div class="col-md-4 mb-2 pr-0">
              <el-select
                v-model="orgid"
                placeholder="Organisation"
                clearable
                filterable
                remote
                :remote-method="getOrganizations"
                :loading="loading"
                @keyup.enter="handleFilt"
              >
                <el-option
                  v-for="item in orgOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-md-2 mb-2 pr-0">
              <el-button
                right
                type="primary"
                @click="handleFilter"
                plain
                style="margin-left: 12px"
                >Filtrer
              </el-button>
            </div>
             </div> 
            <el-table :data="list" style="width: 100%; margin-bottom: 10px">
              <el-table-column prop="uniqueId" label="Numéro de série" />
              <el-table-column prop="name" label="Nom" />
              <el-table-column prop="organization.name" label="Organisation" />
              <el-table-column prop="status" label="Status" />
            </el-table>
            <el-pagination
              v-if="list.length"
              v-model:currentPage="page"
              v-model:page-size="size"
              background
              layout="total, prev, pager, next, jumper"
              :total="totalElements"
              @current-change="setPage"
              :hide-on-single-page="true"
            /> </el-card
        ></div>
      </el-col>
    </el-row>
  </el-container> -->
</template>

<script>
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { mapState, mapActions } from 'vuex'
import DeviceStates from './components/DeviceStates.vue'
import { clean } from '@/helpers/functions.js'
import _ from 'lodash'
export default {
  components: { DeviceStates },
  data() {
    return {
      page: 1,
      orgOptions: [],
      retailerOptions: [],
      loading: false,
      orgid: '',
      nom: '',
    }
  },
  computed: {
    ...mapGetters({
      organizations: 'organisations/organisations',
      isAppUser: 'auth/isAppUser',
    }),
    ...mapFields('devices', ['filter']),
    ...mapFields('devices', ['devicesFilter']),

    ...mapState('devices', ['insights', 'devicesByStatus', 'params']),
    list() {
      return _.get(this.devicesByStatus, 'content', [])
    },
    totalElements() {
      return _.get(this.devicesByStatus, 'totalElements', 0)
    },
    size() {
      return _.get(this.devicesByStatus, 'size', 0)
    },
  },
  mounted() {
    this.$store.dispatch('devices/getInsightsApi', {})
  },
  methods: {
    // setPage(page) {
    //   this.page = page
    //   this.$store.dispatch('devices/getByStatusApi', {
    //     params: { ...this.params, page: page - 1 },
    //   })
    // },
    ...mapActions({
      getDevicesByStatus: 'devices/getByStatusApi',
    }),

    setPage(val) {
      this.filter.page = val - 1
      let payload = {
        params: {
          ...this.filter,
        },
      }

      this.getDevicesByStatus(payload)
    },

    getOrganizations(query) {
      if (query) {
        this.loading = true
        let payload = {
          params: {
            q: query,
          },
        }
        this.$store
          .dispatch('organisations/getOrganisations', payload)
          .then((data) => {
            this.loading = false
            this.orgOptions = data
          })
      } else {
        this.orgOptions = []
      }
    },

    handleFilter() {
      this.filter = {
        name: this.nom,
        organizationId: this.orgid,
        retailerId: null,
        q: null,
        make: null,
        model: null,
        status: null,
        motionState: null,
        page: 0,
      }

      let payload = {
        params: {
          ...clean(this.filter),
        },
      }
      this.devicesFilter = true
      this.$store.dispatch('devices/getByStatusApi', payload)
    },
    // handleFilter() {
    //   this.filter = {
    //     organizationId: this.orgid,
    //   }

    //   let payload = {
    //     params: {
    //       ...this.filter,
    //     },
    //   }
    //   this.devicesFilter = true
    //   this.$store.dispatch('devices/getByStatusApi', payload)
    // },

    // handleFilterr() {
    //   this.filter = {
    //     name: this.nom,
    //   }

    //   let payload = {
    //     params: {
    //       ...this.filter,
    //     },
    //   }
    //   this.devicesFilter = true
    //   this.$store.dispatch('devices/getByStatusApi', payload)
    // },
  },
}
</script>

<style>
body {
  margin: 0;
}
.el-container {
  min-height: 100%;
}
.el-col {
  margin-bottom: 20px;
}
.el-header {
  display: flex;
  align-items: center;
  border-bottom: solid 1px #e6e6e6;
}
.el-footer {
  border-top: solid 1px #e6e6e6;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 100%;
}
</style>
